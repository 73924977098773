import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { NextSeo } from 'next-seo';
import { FC, HTMLAttributes } from 'react';
import toast from 'react-hot-toast';

import { Container } from '../components/auth/Container';
import { Loader } from '../components/common/Loader';
import { SITE_DATA } from '../constants/site-data';
import DashboardLayout from './Dashboard';

type Props = {
  title?: string;
  showSideBar?: boolean;
  isProtected?: boolean;
};

type LayoutProps = Props & HTMLAttributes<HTMLElement>;

const Layout: FC<LayoutProps> = ({
  children,
  title,
  isProtected = false, // for protected routes like admin
}) => {
  const router = useRouter();
  const { status } = useSession({
    required: isProtected,
    onUnauthenticated: () => {
      toast.error('You must be signed in to continue');
      router.push('/login');
    },
  });

  const isLoading = status === 'loading';
  const isUnauthenticated = status === 'unauthenticated';

  if (isProtected) {
    if (isLoading) {
      return (
        <Container>
          <Loader />
        </Container>
      );
    }
    if (isUnauthenticated) return null;
  }

  const pageTitle = title ? `${title} | ${SITE_DATA.title}` : SITE_DATA.title;

  return (
    <>
      <NextSeo
        title={pageTitle}
        canonical={SITE_DATA.canonical_url}
        description={SITE_DATA.description}
        twitter={{
          handle: SITE_DATA.twitter_user,
        }}
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: SITE_DATA.canonical_url,
          site_name: SITE_DATA.title,
        }}
      />
      {!isUnauthenticated && <DashboardLayout> {children}</DashboardLayout>}
      {isUnauthenticated && <main>{children}</main>}
    </>
  );
};

export default Layout;
