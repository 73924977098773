import { Bars3Icon } from '@heroicons/react/24/outline';
import { useAnalytics } from 'contexts/analytics';
import { ReactNode, useState } from 'react';
import { trpc } from 'utils/trpc';

import Sidebar from '../components/structure/Sidebar';
const DashboardLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const analytics = useAnalytics();
  const user = trpc.auth.getCurrentUser.useQuery();

  analytics.identify(user?.data?.id, {
    email: user?.data?.email,
    name: user?.data?.name,
  });

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
      <div className="flex flex-col md:pl-64">
        <div className="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="md:pl-64">{children}</main>
      </div>
    </>
  );
};

export default DashboardLayout;
