export const SITE_URL = 'https://app.pamplemousse.app';
export const SITE_DOMAIN = 'pamplemousse.app';

export const SITE_DATA = {
  name: 'Pamplemousse',
  title: 'Pamplemousse — Creez des factures aux normes!',
  description:
    'Creez des factures aux normes - Commencez gratuitement avec Pamplemousse!',
  canonical_url: SITE_URL,
  domain: SITE_DOMAIN,
  twitter_user: '@PamplemousseHQ',
  image: `${SITE_URL}/images/og-image.webp`,
};
