import { FC } from 'react';

export const Container: FC<{ children?: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return (
    <div className="mx-auto flex h-screen w-full max-w-lg flex-col items-center justify-center gap-y-5 px-4">
      {children}
    </div>
  );
};
